<template>
    <div
        id="processArea"
        class="el_height"
        v-loading="loading"
    >
        <header class="processArea_header">
            <p>办理页面</p>
            <div class="headerBtn">
                <template v-for="(item, index) in btns">
                    <button
                        v-if="item.isShow != false"
                        :key="index"
                        @click="btnClick(item.Method)"
                    >
                        <i class="iconfont" :class="item.Icon"></i>
                        <span v-if="(item.CustomName || '') != ''">{{ item.CustomName }}</span>
                        <span v-else>{{ item.ActionName }}</span>
                    </button>
                </template>
                <button @click="printing()" v-if="!whetherAnnexData && !whetherVueFrom">
                    <i class="iconfont icondayin"></i>
                    <span>打印</span>
                </button>
            </div>
        </header>
        <div class="processArea_content" :class="{'isProcedure': !HistoryNewRecordList}">
            <div class="el_left listArea">
                <el-tabs
                    v-model="formActiveName"
                    @tab-click="handleClick"
                    :before-leave="beforeHandle"
                >
                    <template v-for="(item,index) in forms">
                        <el-tab-pane
                            :key="index"
                            :label="item.FormName"
                            :name="item.FormUrl"
                        >
                            <span slot="label">
                                <i class="iconfont iconbiaodan"></i>
                                {{ item.FormName }}
                            </span>
                        </el-tab-pane>
                    </template>
                </el-tabs>
                <div class="formContent">
                    <formPage
                        v-if="whetherConfigFrom"
                        :index="thisFormIndex"
                        :form-data="thisForm"
                        :form-use-in-jy-flow="code.FormUseInJyFlow"
                        :term="code.term"
                        :business-instance-id="BusinessInstanceId"
                        :current-business-process-id="CurrentBusinessProcessId"
                        :tenant-id="tenant_id"
                    ></formPage>
                    <component
                        ref="vueForm"
                        :is="zjName"
                        v-if="whetherVueFrom"
                        :vue-form-params="vueFormParams"
                        :code="tableCode"
                        :extr="tableExtr"
                    ></component>
                </div>
            </div>
            <div class="el_right process">
                <el-tabs v-model="processActiveName" @tab-click="processAttachmentSwitching">
                    <el-tab-pane label="审批过程" name="spgc"></el-tab-pane>
                    <el-tab-pane label="附件资料" name="fjzl"></el-tab-pane>
                    <el-tab-pane label="办理日志" name="blrz"></el-tab-pane>
                </el-tabs>
                <div class="processAttachmentView" :is="currentView" :current-view-data="currentViewData"></div>
            </div>
        </div>
        <el-dialog
            center
            width="7.5rem"
            title="选取人员"
            class="selectionOfficer"
            :visible.sync="choseMembersVisible"
            :append-to-body="true"
        >
            <choseMembers
                :is-radio="true"
                :business-instance-id="BusinessInstanceId"
                :specify-role-data="specifyRoleData"
                @obtainPersonnel="obtainPersonnel"
                @deselected="deselected"
            ></choseMembers>
        </el-dialog>
        <el-dialog
            center
            width="10rem"
            title="选取节点"
            class="selectionNode"
            :visible.sync="selectionNodeVisible"
            :append-to-body="true"
        >
            <selectionNode
                :is-free-submission="isFreeSubmission"
                :submit-link-data="submitLinkData"
                :business-instance-id="BusinessInstanceId"
                :current-business-process="CurrentBusinessProcess"
                @selectionNodeCallback="selectionNodeCallback"
                @deselected="deselected"
            ></selectionNode>
        </el-dialog>
    </div>
</template>

<script>
// 表单组件
import formPage from '@/components/form/formPage';
// 选择人员组件
import choseMembers from './roleSelection/choseMembers';
// 选择节点
import selectionNode from './selectionNode/index_new';
import store from '@/store';

export default {
    // 业务实例_流程模块
    name: 'business-instance',
    components: {
        formPage,
        choseMembers,
        selectionNode,
    },
    props: {
        code: {
            type: [Array, Object, String ],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            // 流程配置
            ConfigBusiness: '',
            // 表单集合
            forms: [],
            // 按钮集合
            btns: {},
            // 附件集合
            FileGroupInstanceList: {},
            // 附件按钮权限
            BusinessFileGroupConfig: {},
            // 流程历史记录，包括环节记录和评论记录
            HistoryNewRecordList: {},
            // 环节列表
            FlowNewChartList: {},
            // 加载中状态
            loading: true,
            // 当前打开表单
            thisForm: '',
            // 当前表单下标
            thisFormIndex: 0,
            // 当前表单是否以保存
            thisFormIsSave: true,
            // 流程信息
            BusinessInstanceInfo: {},
            // 流程id
            BusinessInstanceId: '',
            // 业务流程id
            CurrentBusinessProcessId: '',
            // 流程环节标识
            BusinessPhaseId: '',
            // 下一环节（会签节点）数据
            submitLinkData: {},
            // 环节类型
            PhaseType: '',
            // 是否是自由提交
            isFreeSubmission: false,
            // 附件状态
            isAnnexData: null,
            // 当前打开组件是否是附件
            whetherAnnexData: false,
            // 当前打开组件是否是vue自定义组件
            whetherVueFrom: false,
            // 当前打开组件是否是配置表单
            whetherConfigFrom: true,

            // 选择人员弹出层状态
            choseMembersVisible: false,
            // 选择人员[指定角色]环节数据
            specifyRoleData: {},

            // 当前流程环节信息
            CurrentBusinessProcess: {},
            // 当前流程提示数据集合
            BusinessRemindList: [],
            // 下一环节审批人id集合
            NextApproverIds: '',
            // 下一环节审批人name集合
            NextApproverNames: '',
            // 选择节点弹出层状态
            selectionNodeVisible: false,
            // vue表单路径
            t_url: 'salesBusiness/contractManage/contractPrice/contractPriceForm',
            // 默认选中第一个tab
            formActiveName: '',
            // 传递给vueForm参数集合
            vueFormParams: {
                businessInstanceId: '', // 流程id
                PKValues: '', // 表单主键
                originalContractVersionId: '', // 原合同版本id
                IsRead: false, // 是否只读
                formId: '',
                standard_code: '',
            },
            // vue列表-code
            tableCode: '',
            // vue列表-extr
            tableExtr: {},
            // 当前租户id
            tenant_id: '',

            // 是否填写意见
            isOpinion: false,
            // 是否是当前办理填写的意见
            isNewestOpinion: true,
            // 是否需要填写意见
            isFillOpinion: true,

            processActiveName: 'spgc',

            // 办理流程附件日志路径
            currentView: '',
            currentViewData: {},
        };
    },

    created() {
        if (this.code) {
            if (this.code.tenant_id ) {
                this.tenant_id = this.code.tenant_id;
            }
            if (this.code.procedure) {
                this.isAnnexData = true;
            } else {
                this.isAnnexData = false;
            }
        }
        // 页面加载获取流程数据
        this.getProcessData();
    },
    mounted() {},
    watch: {
        // 附件隐藏保存按钮
        whetherAnnexData(newVal) {
            this.hideSaveBtn(newVal);
        },
        // 表单类型=5(配置vue列表)隐藏保存按钮
        'thisForm.FormType'(newVal) {
            if (newVal === '5') {
                this.hideSaveBtn(newVal);
            } else {
                this.showSaveBtn();
            }
        },
        // 只读表单隐藏保存按钮
        'thisForm.IsRead'(newVal) {
            if (newVal) {
                this.hideSaveBtn(newVal);
            } else {
                this.showSaveBtn();
            }
        },
    },
    computed: {
        // 按类型动态加载表单
        zjName: function () {
            const t_url = this.t_url;
            const myComponent = () => import(`@/views/${t_url}.vue`);
            return myComponent;
        },
    },
    methods: {
        // 获取流程数据
        getProcessData() {
            let getUrl = '';
            if (this.tenant_id !== '') {
                getUrl = `/interfaceApi/basicplatform/${this.code.businessInstanceUrl}?tenant_id=${this.tenant_id}`;
            } else {
                getUrl = `/interfaceApi/basicplatform/${this.code.businessInstanceUrl}`;
            }
            this.$axios.get(getUrl)
                .then(async res => {
                    // 流程配置
                    this.ConfigBusiness = res.ConfigBusiness;
                    // 判断是否需要填写意见， BusinessCode等于HTPS-LS2不需要填写意见
                    if (this.ConfigBusiness.BusinessCode === 'HTPS-LS2') {
                        this.isFillOpinion = false;
                    }
                    // 按钮集合
                    this.btns = res.BusinessActionList;
                    // 附件集合
                    this.FileGroupInstanceList = (res.FileGroupInstanceList || []).filter(item => item.FileName !== '现场照片');
                    // 附件按钮权限
                    this.BusinessFileGroupConfig = res.BusinessFileGroupConfig;
                    // 流程历史记录，包括环节记录和评论记录
                    this.HistoryNewRecordList = res.HistoryNewRecordList;
                    // 环节列表
                    this.FlowNewChartList = res.FlowNewChartList;
                    // 流程id
                    this.BusinessInstanceId = res.BusinessInstanceId;
                    // 流程基本信息
                    this.BusinessInstanceInfo = res.BusinessInstanceInfo;
                    if (res.CurrentBusinessProcess) {
                        // 当前流程环节信息
                        this.CurrentBusinessProcess = res.CurrentBusinessProcess;
                        // 业务流程id
                        this.CurrentBusinessProcessId = res.CurrentBusinessProcess.CurrentBusinessProcessId;
                    }

                    this.BusinessRemindList = res.BusinessRemindList || [];
                    // 判断打开有没有传流程ID  启动流程没有传流程ID
                    if (this.code.businessInstanceId) {
                        this.BusinessInstanceId = this.code.businessInstanceId;
                    }

                    // IsNotRequiredOpinion = true意见选填，= false 意见必填
                    if (!this.CurrentBusinessProcess.IsNotRequiredOpinion) {
                        // 拉取审批意见
                        await this.getApprovalComments();
                    } else {
                        this.isOpinion = true;
                    }

                    // 判断有没有表单数据
                    if (res.BusinessFormList.length > 0) {
                        // 表单集合
                        this.forms = res.BusinessFormList;
                        // 当前打开表单
                        this.thisForm = res.BusinessFormList[0];
                        // 默认选中第一个
                        this.formActiveName = res.BusinessFormList[0].FormUrl;
                        // 表单编辑状态（false:未编辑，true:有编辑。切换表单根据有没有编辑提示是否保存）
                        window.formEditingStatus = false;
                        if (this.thisForm.FormType === '2') {
                            // 配置表单
                            this.thisFormIndex = 0;
                            this.whetherConfigFrom = true;
                            this.whetherAnnexData = false;
                            this.whetherVueFrom = false;
                        } else if (['3', '4', '5'].indexOf(this.thisForm.FormType) > -1) {
                            // 3、vue表单, 4、配置列表，5、vue列表
                            this.whetherVueFrom = true;
                            this.whetherConfigFrom = false;
                            this.whetherAnnexData = false;
                            this.vueFormParams.businessInstanceId = this.BusinessInstanceId;
                            this.vueFormParams.PKValues = this.thisForm.FormKeyValue;
                            this.vueFormParams.code = this.code;
                            this.vueFormParams.IsRead = this.thisForm.IsRead;
                            this.vueFormParams.formId = this.thisForm.FormKeyValue;
                            if (this.BusinessInstanceInfo?.standard_code) {
                                this.vueFormParams.standard_code = this.BusinessInstanceInfo.standard_code;
                            }
                            if (this.thisForm.FormUrl.indexOf('tableList') === -1) {
                                this.t_url = this.thisForm.FormUrl;
                            } else {
                                this.t_url = this.thisForm.FormUrl.split('?')[0];
                                this.tableCode = { TableCode: this.$queryString(this.thisForm.FormUrl.split('?')[1], 'TableCode') };
                                this.tableExtr.vueFormParams = this.vueFormParams;
                            }
                        }
                    } else {
                        this.formActiveName = 'enclosure';
                        this.whetherAnnexData = true;
                        this.whetherConfigFrom = false;
                        this.whetherVueFrom = false;
                        this.$message({
                            showClose: true,
                            message: '当前环节未配置表单！',
                            type: 'warning',
                        });
                    }
                    this.loadProcessAttachment('spgc');
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message({
                        showClose: true,
                        message: error.ErrorCode.Message,
                        type: 'error',
                    });
                });
        },

        // 加载流程附件日志
        loadProcessAttachment(type) {
            if (type === 'spgc') {
                this.currentView = () => import('./processArea/index');
                this.currentViewData = {
                    historyNewRecordList: this.HistoryNewRecordList,
                    flowNewChartList: this.FlowNewChartList,
                    currentBusinessProcess: this.CurrentBusinessProcess,
                    businessInstanceId: this.code.businessInstanceId,
                    businessRemindList: this.BusinessRemindList,
                };
            } else if (type === 'fjzl') {
                this.currentView = () => import('./procedureAnnex/index');
                this.currentViewData = {
                    fileGroupInstanceList: this.FileGroupInstanceList,
                    businessFileGroupConfig: this.BusinessFileGroupConfig,
                    businessInstanceId: this.code.businessInstanceId,
                };
            } else if (type === 'blrz') {
                this.currentView = () => import('./handlingLog/index');
                this.currentViewData = {
                    businessInstanceId: this.code.businessInstanceId,
                };
            }

        },

        // tab切换前事件，判断表单是否编辑未保存
        beforeHandle() {
            let returnStatus = true;
            if (window.formEditingStatus) {
                this.thisFormIsSave = false;
                returnStatus = false;
            } else {
                this.thisFormIsSave = true;
            }
            return returnStatus;
        },
        // 点击tab切换页签
        handleClick(tab) {
            // 判断有修改未保存不让切换
            if (this.thisFormIsSave === false) {
                this.$confirm('表单有修改，切换会清空数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.tabSwitch(tab);
                }).catch(() => {
                    return;
                });
            } else {
                this.tabSwitch(tab);
            }
        },
        // 切换执行
        tabSwitch(tab) {
            // 附件表单
            // 表单编辑状态（false:未编辑，true:有编辑。切换表单根据有没有编辑提示是否保存）
            window.formEditingStatus = false;
            if (tab.name === 'enclosure') {
                this.formActiveName = 'enclosure';
                this.whetherAnnexData = true;
                this.whetherConfigFrom = false;
                this.whetherVueFrom = false;
            } else {
                this.thisForm = this.forms[tab.index];
                this.formActiveName = this.forms[tab.index].FormUrl;
                if (this.thisForm.FormType === '2') {
                    // 配置表单
                    this.thisFormIndex = tab.index;
                    this.whetherConfigFrom = true;
                    this.whetherAnnexData = false;
                    this.whetherVueFrom = false;
                } else if (['3', '4', '5'].indexOf(this.thisForm.FormType) > -1) {
                    // vue表单|| 列表
                    this.whetherVueFrom = true;
                    this.whetherConfigFrom = false;
                    this.whetherAnnexData = false;
                    this.vueFormParams.businessInstanceId = this.BusinessInstanceId;
                    this.vueFormParams.PKValues = this.thisForm.FormKeyValue;
                    this.vueFormParams.code = this.code;
                    this.vueFormParams.IsRead = this.forms[tab.index].IsRead;
                    if (this.BusinessInstanceInfo?.standard_code) {
                        this.vueFormParams.standard_code = this.BusinessInstanceInfo.standard_code;
                    }
                    if (this.thisForm.FormUrl.indexOf('tableList') === -1) {
                        this.t_url = this.thisForm.FormUrl;
                    } else {
                        this.t_url = this.thisForm.FormUrl.split('?')[0];
                        const QueryStringStr = `BusinessInstanceId=${this.BusinessInstanceId}&BusinessCode=${this.ConfigBusiness.BusinessCode}`;
                        this.tableCode = {
                            TableCode: this.$queryString(this.thisForm.FormUrl.split('?')[1], 'TableCode'),
                            QueryString: QueryStringStr,
                        };
                        this.tableExtr.vueFormParams = this.vueFormParams;
                    }
                }
            }
        },
        // 流程按钮触发后台配置事件
        btnClick(btnEvent) {
            // eslint-disable-next-line no-eval
            eval(btnEvent);
        },
        // 流程提交【提交前验证意见】
        async submit() {
            // 拉取审批意见
            await this.getApprovalComments();
            if (Object.keys(this.CurrentBusinessProcess).length === 0) {
                this.$message({
                    showClose: true,
                    message: '请先保存表单!',
                    type: 'warning',
                });
                return;
            }
            if (this.isFillOpinion && !this.isOpinion) {
                this.$message({
                    showClose: true,
                    message: '请先填写意见！',
                    type: 'warning',
                });
                return;
            }
            // 提交验证意见完成
            this.submitVerificationAdopt();
        },
        // 提交验证意见完成
        async submitVerificationAdopt() {
            this.isFreeSubmission = false;
            // 获取环节数据请求
            const linkData = await this.getLinkDataRequest(this.BusinessInstanceId, this.CurrentBusinessProcess.CurrentBusinessProcessId);
            /**
             * @description     如果接口返回数据里SubmitPhaseNodes=null,则走结束方法
             * @param           {*} linkData
             * @return          {*}
             * @time            2022年9月2日11:42:13
             *                  二次修改：2022年11月17日  【禅道bug1938】
             */            
            if (!linkData.SubmitPhaseNodes && !linkData.SubmitPhaseModel) {
                this.endVerificationAdopt();
            } else {
                // 下一环节（会签节点）数据
                this.submitLinkData = {};
                // 提交会签环节
                if (!linkData.SubmitPhaseModel && linkData.SubmitPhaseNodes) {
                    this.BusinessPhaseId = linkData.BusinessPhaseId;
                    // 下一环节（会签节点）数据
                    this.submitLinkData = linkData.SubmitPhaseNodes;
                    // 点击提交 打开选择节点弹出层
                    this.selectionNodeVisible = true;
                // 判断环节提交模式和审批人类别，环节提交模式=1（默认环节），审批人类别=2（角色），打开选择人员弹出层
                } else if (
                    linkData.PhaseSubmitMode === 1
                    && linkData.SubmitPhaseModel?.ApproverType === 2
                    && linkData.SubmitPhaseModel?.IsAllowEditApprover === true
                ) {
                    if (linkData.SubmitPhaseModel.ApproverRoleId) {
                        this.specifyRoleData = linkData;
                        // 点击提交 打开选择人员弹出层
                        this.choseMembersVisible = true;
                    } else {
                        this.$message({
                            showClose: true,
                            message: '审批人类别为指定角色，当前未配置角色！',
                            type: 'warning',
                        });
                    }
                // 判断环节提交模式和审批人类别，环节提交模式=2或者=3（指定环节、自由环节）
                } else if ([2, 3].indexOf(linkData.PhaseSubmitMode) > -1) {
                    this.PhaseType = linkData.PhaseType;
                    this.BusinessPhaseId = linkData.BusinessPhaseId;
                    // 点击提交 打开选择节点弹出层
                    this.selectionNodeVisible = true;
                } else {
                    const data = {
                        BusinessInstanceId: this.BusinessInstanceId,
                        CurrentBusinessProcessId: linkData.CurrentBusinessProcessId,
                        TransferMode: 10,
                    };
                    let newText = '';
                    if (linkData?.SubmitPhaseModel?.ApproverName) {
                        newText = `提交到【${linkData.SubmitPhaseModel?.BusinessPhaseName}】环节的
                                    【${linkData.SubmitPhaseModel.ApproverName}】进行办理？`;
                    } else {
                        newText = `提交到【${linkData.SubmitPhaseModel?.BusinessPhaseName}】环节进行办理？`;
                    }
                    this.$confirm(newText, '确认提交', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }).then(() => {
                        this.submitAPI(data, '提交成功!');
                    }).catch(() => {
                        this.$message({
                            showClose: true,
                            type: 'info',
                            message: '已取消提交',
                        });
                    });
                }
            }
        },

        // 自由提交
        customSubmit() {
            // 下一环节（会签节点）数据
            this.submitLinkData = {};
            this.isFreeSubmission = true;
            this.PhaseType = this.CurrentBusinessProcess.PhaseType;
            this.BusinessPhaseId = this.CurrentBusinessProcess.BusinessPhaseId;

            // 点击提交 打开选择节点弹出层
            this.selectionNodeVisible = true;
        },
        // 跨级审批【提交前验证意见】
        async crossApproval() {
            // 拉取审批意见
            await this.getApprovalComments();
            if (Object.keys(this.CurrentBusinessProcess).length === 0) {
                this.$message({
                    showClose: true,
                    message: '请先保存表单!',
                    type: 'warning',
                });
                return;
            }
            if (this.isFillOpinion && !this.isOpinion) {
                this.$message({
                    showClose: true,
                    message: '请先填写意见！',
                    type: 'warning',
                });
                return;
            }
            // 跨级审批验证意见完成
            this.crossApprovalVerificationAdopt();
        },
        // 跨级审批验证意见完成
        crossApprovalVerificationAdopt() {
            const data = {
                BusinessInstanceId: this.BusinessInstanceId,
                CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                TransferMode: 10,
            };
            this.submitAPI(data, '操作成功!', '/interfaceApi/basicplatform/simpleflow/crosshierarchy/approval');
        },


        // 跨级退回【提交前验证意见】
        async crossBackProcess() {
            // 拉取审批意见
            await this.getApprovalComments();
            if (Object.keys(this.CurrentBusinessProcess).length === 0) {
                this.$message({
                    showClose: true,
                    message: '请先保存表单!',
                    type: 'warning',
                });
                return;
            }
            if (this.isFillOpinion && !this.isOpinion) {
                this.$message({
                    showClose: true,
                    message: '请先填写意见！',
                    type: 'warning',
                });
                return;
            }
            // 跨级退回验证意见完成
            this.crossBackProcessVerificationAdopt();
        },
        // 跨级退回验证意见完成
        crossBackProcessVerificationAdopt() {
            const data = {
                BusinessInstanceId: this.BusinessInstanceId,
                CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                TransferMode: 21,
            };
            this.submitAPI(data, '操作成功!', '/interfaceApi/basicplatform/simpleflow/crosshierarchy/approval');
        },


        // 原材料调价退回供应商【提交前验证意见】
        async MaterialPriceAdjustPostback() {
            // 拉取审批意见
            await this.getApprovalComments();
            if (Object.keys(this.CurrentBusinessProcess).length === 0) {
                this.$message({
                    showClose: true,
                    message: '请先保存表单!',
                    type: 'warning',
                });
                return;
            }
            if (this.isFillOpinion && !this.isOpinion) {
                this.$message({
                    showClose: true,
                    message: '请先填写意见！',
                    type: 'warning',
                });
                return;
            }
            // 原材料调价退回供应商验证意见完成
            this.materialPriceAdjustPostbackVerificationAdopt();
        },
        // 原材料调价退回供应商验证意见完成
        materialPriceAdjustPostbackVerificationAdopt() {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/Procurement/material/price/adjust/postback/${this.BusinessInstanceId}`)
                .then(() => {
                    this.$message({
                        showClose: true,
                        message: '操作成功',
                        type: 'success',
                    });
                    this.loading = false;
                    // 提交流程刷新列表
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    // 关闭弹出层
                    this.$parent.hide();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message({
                        showClose: true,
                        message: error.ErrorCode.Message,
                        type: 'error',
                    });
                });
        },

        // 流程保存，调用流程表单保存
        save() {
            this.loading = true;
            this.formSave();
        },

        // 选择人员回调函数
        obtainPersonnel(data) {
            data.forEach(item => {
                this.NextApproverIds += item.user_id + ',';
                this.NextApproverNames += item.user_name + ',';
            });
            this.NextApproverIds = this.NextApproverIds.substring(0, this.NextApproverIds.length - 1);
            this.NextApproverNames = this.NextApproverNames.substring(0, this.NextApproverNames.length - 1);

            // 判断是否有下一环节审批人id集合和下一环节审批人name集合，有值进行提交
            if (this.NextApproverIds !== '' && this.NextApproverNames !== '' && this.BusinessInstanceId) {
                const ApiData = {
                    BusinessInstanceId: this.BusinessInstanceId,
                    CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                    TransferMode: 10,
                    NextApproverIds: this.NextApproverIds,
                    NextApproverNames: this.NextApproverNames,
                };
                this.choseMembersVisible = false;
                this.submitAPI(ApiData, '提交成功!');
            } else {
                this.$message({
                    showClose: true,
                    message: '请先选择人员！',
                    type: 'warning',
                });
            }
        },
        // 选择节点回调函数
        selectionNodeCallback(data) {
            this.selectionNodeVisible = false;
            let new_transferMode = null;
            if (data.isBack) { // 提交已审批环节，退回状态
                new_transferMode = 21;
            } else {
                new_transferMode = 10;
            }
            if (data.isAllowEditApprover === false) { // 是否允许修改办理人
                const ApiData = {
                    BusinessInstanceId: this.BusinessInstanceId,
                    CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                    TransferMode: new_transferMode,
                    NextPhsaeId: data.NextPhsaeId,
                    NextApproverIds: data.NextApproverIds,
                    NextApproverNames: data.NextApproverNames,
                };
                this.submitAPI(ApiData, '提交成功!');
            } else if (data.nodeType === 3) { // 会签节点提交，添加MultiPhase参数
                const ApiData = {
                    BusinessInstanceId: this.BusinessInstanceId,
                    CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                    TransferMode: new_transferMode,
                    NextPhsaeId: data.NextPhsaeId,
                    NextApproverIds: data.NextApproverIds,
                    NextApproverNames: data.NextApproverNames,
                    MultiPhase: data.nodeData,
                };
                this.submitAPI(ApiData, '提交成功!');
            } else if (data.NextPhsaeId !== 'null' && data.NextApproverIds !== 'null' && data.NextApproverNames !== 'null' ) {
                const ApiData = {
                    BusinessInstanceId: this.BusinessInstanceId,
                    CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                    TransferMode: new_transferMode,
                    NextPhsaeId: data.NextPhsaeId,
                    NextApproverIds: data.NextApproverIds,
                    NextApproverNames: data.NextApproverNames,
                };
                this.submitAPI(ApiData, '提交成功!');
            } else if (data.IsCrossHierarchy === true) { // 跨级审批
                const ApiData = {
                    BusinessInstanceId: this.BusinessInstanceId,
                    CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                    TransferMode: new_transferMode,
                    NextPhsaeId: data.NextPhsaeId,
                    NextApproverIds: '',
                    NextApproverNames: '',
                };
                this.submitAPI(ApiData, '提交成功!');
            } else {
                this.$message({
                    showClose: true,
                    message: '请先选择环节人员！',
                    type: 'warning',
                });
            }
        },
        // 取消选择人员、节点回调函数
        deselected() {
            this.choseMembersVisible = false;
            this.selectionNodeVisible = false;
        },
        // 调用表单保存
        formSave() {
            if (this.thisForm.FormType === '2') {
                // 配置表单保存
                const iframeId = 'frameId' + this.thisFormIndex;
                const iframe = window.document.getElementById(iframeId);
                iframe.contentWindow.JyFormSaveFormData(this.JTFlowAfterFormSaveSuccess, this.JTFlowAfterFormSaveError);
            } else if (this.thisForm.FormType === '3') {
                // vue表单保存
                this.$refs.vueForm.save(this.JTFlowAfterFormSaveSuccess, this.JTFlowAfterFormSaveError);
            } else if (['4', '5'].indexOf(this.thisForm.FormType) > -1) {
                this.loading = false;
                return;
            }
        },
        // 流程结束【提交前验证意见】
        async end() {
            // 拉取审批意见
            await this.getApprovalComments();
            if (this.isFillOpinion && !this.isOpinion) {
                this.$message({
                    showClose: true,
                    message: '请先填写意见！',
                    type: 'warning',
                });
                return;
            }
            // 流程结束验证意见完成
            this.endVerificationAdopt();
        },
        // 流程结束验证意见完成
        endVerificationAdopt() {
            this.loading = true;
            const data = {
                BusinessInstanceId: this.BusinessInstanceId,
                CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                TransferMode: 40,
            };
            this.submitAPI(data, '事项已办结!');
        },

        // 流程审批不通过【提交前验证意见】
        async nopass() {
            // 拉取审批意见
            await this.getApprovalComments();
            if (this.isFillOpinion && !this.isOpinion) {
                this.$message({
                    showClose: true,
                    message: '请先填写意见！',
                    type: 'warning',
                });
                return;
            }
            // 流程审批不通过验证意见完成
            this.nopassVerificationAdopt();
        },
        // 流程审批不通过验证意见完成
        nopassVerificationAdopt() {
            this.loading = true;
            const data = {
                BusinessInstanceId: this.BusinessInstanceId,
                CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                TransferMode: 40,
                approval_status: 3,
            };
            this.submitAPI(data, '审批不通过!');
        },

        // 退回
        async sendBack() {
            // 拉取审批意见
            await this.getApprovalComments();
            if (this.isFillOpinion && !this.isOpinion) {
                this.$message({
                    showClose: true,
                    message: '请先填写意见！',
                    type: 'warning',
                });
                return;
            }
            // 退回验证意见完成
            this.sendBackVerificationAdopt();
        },
        // 退回验证意见完成
        sendBackVerificationAdopt() {
            this.loading = true;
            const data = {
                BusinessInstanceId: this.BusinessInstanceId,
                CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                TransferMode: 21,
            };
            this.submitAPI(data, '事项已退回!');
        },

        // 退回提交
        async returnSubmit() {
            const res = await this.phaseReturnStatusRequest(this.BusinessInstanceId, this.CurrentBusinessProcess.BusinessPhaseId);
            // 是退回提交，提交到数据返回的退回环节，不是退回提交，走提交方法
            if (res) {
                const data = {
                    BusinessInstanceId: this.BusinessInstanceId,
                    CurrentBusinessProcessId: this.CurrentBusinessProcess.CurrentBusinessProcessId,
                    TransferMode: 10,
                    NextPhsaeId: res.BusinessPhaseId,
                    NextApproverIds: res.ApproverId,
                    NextApproverNames: res.ApproverName,
                };
                let newText = '';
                if (res.ApproverName) {
                    newText = `提交到【${res.BusinessPhaseName}】环节的【${res.ApproverName}】进行办理？`;
                } else {
                    newText = `提交到【${res.BusinessPhaseName}】环节进行办理？`;
                }
                this.$confirm(newText, '确认提交', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    this.submitAPI(data, '提交成功!');
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: '已取消提交',
                    });
                });
            } else {
                this.submit();
            }
        },

        // 流程提交请求
        submitAPI: function (data, message, url) {
            let requestUrl = '';
            if (url) {
                requestUrl = url;
            } else {
                requestUrl = '/interfaceApi/basicplatform/simpleflow/processes';
            }
            this.$axios
                .post(requestUrl, data)
                .then(() => {
                    this.$message({
                        showClose: true,
                        message: message,
                        type: 'success',
                    });
                    this.loading = false;
                    // 提交流程刷新列表
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                    // 关闭弹出层
                    this.$parent.hide();
                })
                .catch(error => {
                    this.loading = false;
                    this.$message({
                        showClose: true,
                        message: error.ErrorCode.Message,
                        type: 'error',
                    });
                });
        },

        // 启动流程实例
        businessinstance(formPK) {
            const _that = this;
            this.$axios
                .post('/interfaceApi/basicplatform/simpleflow/businessinstance', {
                    ConfigBusinessId: this.ConfigBusiness.BusinessId,
                    BusinessCode: this.ConfigBusiness.BusinessCode,
                    BusinessName: this.ConfigBusiness.BusinessName,
                    BusinessInstanceId: this.BusinessInstanceId,
                })
                .then(res => {
                    // 流程id
                    _that.BusinessInstanceId = res.BusinessInstanceInfo.BusinessInstanceId;
                    // 流程环节标识
                    _that.BusinessPhaseId = res.CurrentBusinessProcess.BusinessPhaseId;
                    // 附件集合赋值
                    _that.FileGroupInstanceList = res.FileGroupInstanceList;
                    // 流程历史记录，包括环节记录和评论记录
                    _that.HistoryNewRecordList = res.HistoryNewRecordList;
                    // 环节列表
                    _that.FlowNewChartList = res.FlowNewChartList;
                    _that.code.procedure = false;

                    // 记录保存过的表单数据
                    this.businessform(formPK);

                    // 加载中状态
                    _that.loading = false;
                    // 当前流程环节信息
                    _that.CurrentBusinessProcess = res.CurrentBusinessProcess;

                    // 启动流程刷新列表
                    store.state.currentOpenList.forEach(item => {
                        item.tableObj.updateTable();
                    });
                })
                .catch(error => {
                    _that.loading = false;
                    this.$message({
                        showClose: true,
                        message: error.ErrorCode.Message,
                        type: 'error',
                    });
                });
        },

        // 保存流程表单成功
        JTFlowAfterFormSaveSuccess(formPK) {
            // 当前打开表单PKValues赋值
            this.thisForm.FormKeyValue = formPK;
            this.loading = true;

            // 保存流程表单成功，判断流程是否是第一次保存，是第一次保存就启动流程实例，不是就记录保存过的表单数据
            if (this.code.procedure) {
                this.businessinstance(formPK);
            } else {
                this.businessform(formPK);
            }
        },
        // 保存流程表单错误
        JTFlowAfterFormSaveError(data) {
            this.loading = false;
            if (typeof data !== 'undefined') {
                this.$message({
                    showClose: true,
                    message: data,
                    type: 'error',
                });
            }
        },
        // 记录保存过的表单数据
        businessform(formPK) {
            this.$axios
                .post('/interfaceApi/basicplatform/simpleflow/businessinstance/businessform', {
                    BusinessInstanceId: this.BusinessInstanceId,
                    BusinessPhaseId: this.BusinessPhaseId,
                    FormId: this.thisForm.FormId,
                    FormName: this.thisForm.FormName,
                    FormUrl: this.thisForm.FormUrl,
                    FormKeyName: this.thisForm.FormKeyName,
                    FormKeyValue: formPK,
                })
                .then(() => {
                    this.$message({
                        showClose: true,
                        message: '保存成功！',
                        type: 'success',
                    });
                    this.loading = false;
                    this.isAnnexData = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message({
                        showClose: true,
                        message: error.ErrorCode.Message,
                        type: 'error',
                    });
                });
        },
        // 打印
        printing() {
            if (this.thisForm.FormType === '2') {
                const iframeId = 'frameId' + this.thisFormIndex;
                const iframe = window.document.getElementById(iframeId);
                iframe.contentWindow.JyFormPrintForm();
            }
        },

        // 隐藏保存按钮
        hideSaveBtn(newVal) {
            this.btns.forEach(item => {
                if (newVal && item.ActionCode === 'save') {
                    item.isShow = false;
                } else {
                    item.isShow = true;
                }
            });
        },
        // 显示保存按钮
        showSaveBtn() {
            this.btns.forEach(item => {
                if (item.ActionCode === 'save') {
                    item.isShow = true;
                }
            });
        },

        // 拉取审批意见
        async getApprovalComments() {
            this.isloading = true;
            const res = await this.getApprovalCommentsRequest();
            // res == null, 没有意见表，不用验证意见是否已填
            if (!res) {
                this.isOpinion = true;
                this.isFillOpinion = false;
                this.isNewestOpinion = true;
            } else if ((res?.opinion || '') !== '') {
                this.isOpinion = true;
                if (res.business_process_id !== this.CurrentBusinessProcess.BusinessProcessId) {
                    this.isNewestOpinion = false;
                } else {
                    this.isNewestOpinion = true;
                }
            }
            this.isloading = false;
        },

        // 办理流程、附件、日志切换
        processAttachmentSwitching(tab) {
            this.loadProcessAttachment(tab.name);
        },

        // 环节退回状态请求
        phaseReturnStatusRequest(businessInstanceId, businessPhaseId) {
            const path = `/interfaceApi/basicplatform/simpleflow/processes/getlastreturnprocess/${businessInstanceId}/${businessPhaseId}`;
            return this.$axios.$get(path, { defEx: true });
        },

        // 拉取审批意见请求
        getApprovalCommentsRequest() {
            const Uid = this.$takeTokenParameters('Uid');
            const url = `${this.ConfigBusiness.BusinessCode}/${this.BusinessInstanceId}/${Uid}`;
            return this.$axios.$post(`/interfaceApi/basicplatform/mobile/get_opinion_byuserid/${url}`, {}, { token: this.newsToken });
        },

        // 获取环节数据请求
        getLinkDataRequest(businessInstanceId, businessPhaseId) {
            const path = `/interfaceApi/basicplatform/simpleflow/processes/getcurrentbusinessprocess/${businessInstanceId}/${businessPhaseId}`;
            return this.$axios.$get(path, { defEx: true });
        },
    },
};
</script>

<style lang="stylus">
#processArea
    width 100%
    height 100%
    box-shadow 2px 2px 30px 0px rgba(0,0,0,0.3)
    .processArea_header
        padding-right 0.56rem
        height 0.56rem
        background #014A88
        display flex
        justify-content space-between
        p
            padding 0 0.2rem
            font-size 0.18rem
            line-height 0.56rem
            text-align left
            color #fff
            background #003969
            position relative
            &:after
                content ''
                width 1.37rem
                height 0.56rem
                background url(./images/headerText.png) no-repeat
                background-size 100%
                position absolute
                left 100%
            &:before
                content ''
                width 100%
                height 2px
                position absolute
                left 0
                bottom 0
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #00FBFF 45%, rgba(255,255,255,0) 100%);
        .headerBtn
            display flex
            align-items center
            button
                border none
                background none
                font-size 0.16rem
                line-height 0.48rem
                height 0.48rem
                cursor pointer
                color #fff
                padding 0 0.1rem
                display flex
                i
                    width 0.2rem
                    height 0.48rem
                    line-height 0.48rem
                    text-align center
                    font-size 0.16rem
                span
                   padding 0 8px 0 3px
                   font-size 0.14rem
                &:hover
                    i
                        width 0.4rem
                        background url(./images/headerBtnBg_l.png) no-repeat
                        background-size 100%
                    span
                        background url(./images/headerBtnBg_r.png) no-repeat
                        background-size 100% 100%

    .processArea_content
        padding-top 0.1rem
        height calc(100% - 0.56rem)
        background: linear-gradient(134deg, #C1D7EF 0%, #EAF6F5 100%);
        &.isProcedure
            .listArea
                width 100%
            .process
                display none
        >div
            height 100%
            &.listArea
                width 75%
                padding 0.1rem
                padding-top 0
            &.process
                width 25%
                background #fff
        .listArea
            .el-tabs
                width 100%
                height 0.48rem
                background rgba(255, 255, 255, 0.6)
                .el-tabs__header
                    margin-bottom 0
                    .el-tabs__nav-wrap
                        &:after
                            display none
                        .el-tabs__nav
                            float initial
                            display flex
                            justify-content center
                            .el-tabs__active-bar
                                display none
                            .el-tabs__item
                                height 0.48rem
                                font-size 0.16rem
                                line-height 0.48rem
                                padding 0
                                display flex
                                span
                                    padding 0 0.2rem
                                    height 0.48rem
                                    font-family AliHYAiHei
                                i
                                    margin-right 0.05rem
                                    display none
                                &.is-active
                                    span
                                        background #fff
                                        color #0078FB
                                        position relative
                                        &:before
                                            content ''
                                            position absolute
                                            bottom 0
                                            left 50%
                                            margin-left -0.07rem
                                            width: 0;
                                            height: 0;
                                            border: 0.07rem solid;
                                            border-color: transparent transparent #0078FB;
                                    &:before,&:after
                                        content ''
                                        width 0.17rem
                                        height 0.48rem
                                        background url(./../../assets/images/popUpForm/tabsBg.png) no-repeat
                                        background-size 100%
                                    &:after
                                        transform: rotateY(180deg);
                                &:hover
                                    span
                                        color #0078FB
                                &.is-focus
                                    box-shadow none
            .formContent
                height calc(100% - 0.48rem)
                background #fff
        .process
            .el-tabs
                width 100%
                height 0.42rem
                .el-tabs__header
                    margin 0
                    .el-tabs__nav-wrap
                        padding 0 0.2rem
                    .el-tabs__nav
                        .el-tabs__item
                            font-size 0.14rem
                            height 0.42rem
                            line-height 0.42rem
                            color #666666
                            &.is-active
                                font-size 0.16rem
                                color #0078FB
                        .el-tabs__active-bar
                            background-color #0078FB
            .processAttachmentView
                padding 0.14rem
.selectionOfficer,
.selectionNode
    .el-dialog
        .el-dialog__header
            padding 0
            height 0.6rem
            background #257ad6
            .el-dialog__title
                color #ffffff
                font-size 0.18rem
                line-height 0.6rem
            .el-dialog__headerbtn
                top 0.15rem
                right 0.15rem
                .el-dialog__close
                    color #fff
                    font-size 0.3rem
        .el-dialog__body
            padding 0
</style>
